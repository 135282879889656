import React, { createContext, useContext, useState } from 'react';

// Create a context for the company
const CompanyContext = createContext();

// Create a provider component
export const CompanyProvider = ({ children }) => {
  const [companyId, setCompanyId] = useState(null);

  return (
    <CompanyContext.Provider value={{ companyId, setCompanyId }}>
      {children}
    </CompanyContext.Provider>
  );
};

// Create a custom hook to use the CompanyContext
export const useCompany = () => {
  return useContext(CompanyContext);
};
