import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../config';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    setResponseMessage('');

    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${apiUrl}/membership/${email}/recovery`,
        headers: {
          'Authorization': 'Bearer YOUR_TOKEN_HERE',
        },
      };

      const response = await axios.request(config);
      setResponseMessage('Link de recuperação enviado para seu e-mail');
      // Redirect to ResetPassword with email passed as state
      setTimeout(() => {
        navigate('/reset/password', { state: { email } });
      }, 2000);
    } catch (error) {
      setResponseMessage('Falha ao enviar o link de recuperação. Por favor, tente novamente.');
    } finally {
      setSubmit(false);
    }
  };

  return (
    <section className="h-100 mb-10 gradient-form" style={{ backgroundColor: 'linear-gradient(115.25deg, #ffffff 0%, #f8f9fd 100.25%)', height: '100vh' }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-6 mt-5 pt-5">
            <div className="card rounded-3 text-black">
              <div className="card-body">
                    <div className="text-center my-auto">
                      <img
                        src="/assets/images/logo/app-logo.png"
                        alt="logo"
                        className='text-center'
                      />
                    </div>
                <form>
                  <p className="text-center">Digite seu e-mail para redefinir sua senha</p>
                  {responseMessage && (
                    <p className="text-center text-danger">{responseMessage}</p>
                  )}
                  <div className="col-md-12">
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </div>
                  <div className="text-center pt-1 mt-4 mb-5 pb-1">
                    {!submit && (
                      <button
                        className="btn btn-light-primary px-4"
                        type="button"
                        onClick={handleSubmit}
                      >
                      Enviar
                      </button>
                    )}
                    {submit && (
                      <button
                        className="btn btn-light-primary px-4"
                        type="button"
                      >
                      Enviando...
                        <span className="spinner-border spinner-border-sm mx-3" role="status"></span>
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
