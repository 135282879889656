import React from 'react'
import InputBox from '../../components/InputBox'
import { Link } from 'react-router-dom'

const CobrarCreate = () => {
return (
<div>
    <div className="page-header">
        <div className="page-block">
            <div className="row align-items-center">
                <div className="col-auto">
                    <div className="page-header-title">
                        <h4 className="m-b-10">Criar cobrança</h4>
                    </div>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={'/'}>Painel </Link> </li> <li className="breadcrumb-item">
                            <Link to={'/charge'}>Cobrar </Link> </li> <li className="breadcrumb-item">Criar
                            Cobrar</li>
                    </ul>
                </div>
                <div className="col">
                </div>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-sm-12">
            <div className="row">
                <div className="col-xl-10 col-lg-10 mx-auto">
                    <div className="tab-content" id="pills-tabContent">
                        <div className="card">
                            <div className="tab-pane fade show active" id="apps-setting" role="tabpanel"
                                aria-labelledby="landing-apps-setting">
                                <form method="POST" action="#">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-lg-8 d-flex align-items-center">
                                                <h5 className="mb-0">Criar cobrança</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <InputBox name="cpf" label="CPF" />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox name="zip_code" label="Zip Code" />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox name="full_name" label="Name" />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox name="address" label="Address" />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox name="email" label="Email" />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox name="address_number" label="Number" />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox name="phone" label="Phone Number" />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox name="address_complement" label="Complement" />
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="gender" className="form-label">Gender</label>
                                                    <select className="form-select  " id="gender" name="gender">
                                                        <option selected disabled value="" className="link">Select
                                                            Gender</option>
                                                        <option value="Feminino" className="Feminino">Feminino</option>
                                                        <option value="Homem Trans" className="Homem Trans">Homem Trans
                                                        </option>
                                                        <option value="Masculino" className="Masculino">Masculino
                                                        </option>
                                                        <option value="Não Binário" className="Não Binário">Não Binário
                                                        </option>
                                                        <option value="Prefiro não responder"
                                                            className="Prefiro não responder">Prefiro não responder
                                                        </option>
                                                        <option value="Travesti" className="Travesti">Travesti</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <InputBox name="city" label="City" />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox name="region" label="State" />
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox name="country" label="Country" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="text-end">
                                            <Link to={'/charge'} className="btn btn-secondary mx-3">Cancel</Link>
                                            <button type="submit" className=" btn btn-primary ">Save</button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
)
}

export default CobrarCreate