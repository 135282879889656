import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import InputBox from '../../components/InputBox';
import { apiUrl } from '../../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the styles
import { useCompany } from '../../components/CompanyProvider ';
import { enqueueSnackbar } from 'notistack';

const ListenerEdit = () => {
    const { companyId, setCompanyId } = useCompany(); // Use company context

    const { id } = useParams(); // Extract the listener ID from the URL
    const [formData, setFormData] = useState({
        full_name: '',
    });
    const [errors, setErrors] = useState({});
    const [attributes, setAttributes] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState('');
    const [attributeValue, setAttributeValue] = useState('');
    const [attributeList, setAttributeList] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch existing listener data
        const fetchListenerData = async () => {
            try {
                const response = await fetch(`${apiUrl}/${companyId}/listener/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = await response.json();

                if (data.statusCode === 200) {
                    setFormData({
                        full_name: data.value[0].full_name || '',
                    });
                    // setAttributeList(data.attributes || []);
                } else {
                    console.error('Failed to fetch listener data');
                }
            } catch (error) {
                console.error('Error fetching listener data:', error);
            }
        };

        const fetchAttributesData = async () => {
            try {
                const response = await fetch(`${apiUrl}/${companyId}/listener/${id}/attributes`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = await response.json();
                if (data.statusCode === 200) {
                    const formattedAttributes = data.value
                        .filter(attr => attr.attribute_key !== "Identification > Personal > Document number")
                        .map(attr => ({
                            key: attr.attribute_key,
                            value: attr.attribute_value,
                            last_updated_at: attr.last_updated_at
                        }));

                    setAttributeList(formattedAttributes || []);
                } else {
                    console.error('Failed to fetch attributes');
                }
            } catch (error) {
                console.error('Error fetching attributes:', error);
            }
        };

        // Fetch attributes list for selection
        const fetchAttributes = async () => {
            try {
                const response = await fetch(`${apiUrl}/attributes`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    setAttributes(data.value || []);
                } else {
                    console.error('Failed to fetch attributes');
                }
            } catch (error) {
                console.error('Error fetching attributes:', error);
            }
        };

        fetchListenerData();
        fetchAttributesData();
        fetchAttributes();
    }, [id,companyId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAttributeChange = (e) => {
        setSelectedAttribute(e.target.value);
    };

    const handleAttributeValueChange = (e) => {
        setAttributeValue(e.target.value);
    };

    const handleAddAttribute = () => {
        if (selectedAttribute && attributeValue) {
            if (editIndex !== null) {
                // If editing an existing attribute
                const updatedAttributeList = attributeList.map((attr, index) =>
                    index === editIndex ? { key: selectedAttribute, value: attributeValue } : attr
                );
                setAttributeList(updatedAttributeList);
                setEditIndex(null);
            } else {
                // Add new attribute
                setAttributeList([
                    ...attributeList,
                    { key: selectedAttribute, value: attributeValue},
                ]);
            }
            setSelectedAttribute('');
            setAttributeValue('');
        } else {
            enqueueSnackbar('O atributo ou valor selecionado está ausente');
        }
    };

    const handleEditAttribute = (index) => {
        const attribute = attributeList[index];
        setSelectedAttribute(attribute.key);
        setAttributeValue(attribute.value);
        setEditIndex(index);
    };

    const handleDeleteAttribute = (index) => {
        setAttributeList(attributeList.filter((_, i) => i !== index));
        if (editIndex === index) {
            setEditIndex(null);
        }
    };

    const handleSubmit = async () => {
        setErrors({}); // Clear previous errors
        const { full_name } = formData;
        const payload = {
            full_name,
            attributes: attributeList
        };
        try {
            const endpoint = `${apiUrl}/${companyId}/listener/${id}`;
            const token = localStorage.getItem('token');

            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            if (data.statusCode === 200) {
                enqueueSnackbar("Record Save Successfuly!");
                navigate('/listener'); // Redirect to listener list on success
            } else {
                // Handle errors
                setErrors(data.errors || { general: data.msg });
                enqueueSnackbar(data.msg);
            }
        } catch (error) {
            console.error('Error posting data: ', error);
            enqueueSnackbar('An error occurred while updating the listener');
        }
    };
   

    return (
        <div>
            <ToastContainer />
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="page-header-title">
                                <h4 className="m-b-10">Atualizar dados do ouvinte</h4>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/'}>Painel</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={'/listener'}>Ouvinte</Link>
                                </li>
                                <li className="breadcrumb-item">Atualizar dados do ouvinte</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-xl-10 col-lg-10 mx-auto">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="card">
                                    <form>
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-lg-8 d-flex align-items-center">
                                                    <h5 className="mb-0">Informações gerais</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {Object.keys(formData).map((key) => (
                                                    <div className="col-md-12" key={key}>
                                                        <div className="form-group">
                                                            <InputBox
                                                                name={key}
                                                                id={key}
                                                                label={key.replace('_', ' ').toUpperCase()}
                                                                value={formData[key]}
                                                                onChange={handleInputChange}
                                                                className={`form-control ${errors[key] ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors[key] && <div className="invalid-feedback">{errors[key]}</div>}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card">
                                    <form>
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-lg-8 d-flex align-items-center">
                                                    <h5 className="mb-0">Atributos</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className='col-md-5'>
                                                    <div className="form-group">
                                                        <label htmlFor="att" className="form-label">Tipo</label>
                                                        <select
                                                            className={`form-select ${errors.att ? 'is-invalid' : ''}`}
                                                            id="att"
                                                            name="att"
                                                            value={selectedAttribute}
                                                            onChange={handleAttributeChange}
                                                        >
                                                            <option value="" disabled>Clique e selecione um tipo</option>
                                                            {attributes.map((attribute) => (
                                                                <option key={attribute.id} value={attribute.id}>
                                                                    {attribute.id}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.att && <div className="invalid-feedback">{errors.att}</div>}
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <InputBox
                                                        name="attValue"
                                                        id="attValue"
                                                        label={"Valor"}
                                                        value={attributeValue}
                                                        placeholder="Digite o valor do tipo do atributo"
                                                        onChange={handleAttributeValueChange}
                                                        className={`form-control ${errors.attValue ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.attValue && <div className="invalid-feedback">{errors.attValue}</div>}
                                                </div>
                                                <div className='col-md-2 mt-4'>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100"
                                                        onClick={handleAddAttribute}
                                                    >
                                                        {editIndex !== null ? "Update" : "+"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <h5>Atributos identificados</h5>
                                                    <ul className="list-group">
                                                        {attributeList.map((attr, index) => (
                                                            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                                <span>
                                                                <b>{attr.last_updated_at && "Última atualização: "}</b>{attr.last_updated_at}<br/>
                                                                <b> Tipo: </b>{attr.key} <br/>
                                                                <b>Valor: </b> {attr.value} <br/> 
                                                                </span>
                                                                
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light-warning btn-sm mx-2"
                                                                        onClick={() => handleEditAttribute(index)}
                                                                    >
                                                                        <i className="ti ti-pencil"></i>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light-danger btn-sm"
                                                                        onClick={() => handleDeleteAttribute(index)}
                                                                    >
                                                                        <i className="ti ti-trash"></i>
                                                                    </button>
                                                                </div>
                                                               
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="text-end">
                                    <Link to={'/listener'} className="btn btn-secondary mx-3">Cancel</Link>
                                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save</button>
                                </div>
                                {errors.general && <div className="alert alert-danger">{errors.general}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListenerEdit;
