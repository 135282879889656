import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../config';

const Charge = () => {
const [charge, setCobranças] = useState([]);

useEffect(() => {
fetchCobranças(); // Fetch charge when component mounts
}, []);

const fetchCobranças = async () => {
try {
// Replace with actual API endpoint
const response = await fetch(baseUrl+'/Cobrançasdata.js');
if (!response.ok) {
throw new Error('Network response was not ok');
}
const data = await response.json();
setCobranças(data); // Set charge state with fetched data
} catch (error) {
console.error('Error fetching data: ', error);
// Handle error state or alert user
}
};

return (
<div>
    <div className="page-header">
        <div className="page-block">
            <div className="row align-items-center">
                <div className="col-auto">
                    <div className="page-header-title">
                        <h4 className="m-b-10">Cobranças</h4>
                    </div>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={'/'}>Painel </Link> </li> <li className="breadcrumb-item">
                            <Link to={'/charge'}>Cobranças </Link> </li> </ul> </div> <div className="col">
                            
                </div>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-sm-12">
            <table className="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>CNPJ</th>
                        <th>Data Vencto.</th>
                        <th>Preço</th>
                        <th>Status</th>
                        <th>Tipo de fatura</th>
                        <th>Link de pagamento</th>
             

                    </tr>
                </thead>
                <tbody>
                    {charge.map(charge => (
                    <tr key={charge.id}>
                        <td>{charge.id}</td>
                        <td>{charge.cngp}</td>
                        <td>{charge.duedate}</td>
                        <td>{charge.value}</td>
                        <td>{charge.status}</td>
                        <td>{charge.TypeofCollection}</td>
                     
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
</div>
);
};

export default Charge;