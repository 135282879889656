import React from 'react'
const Footer = ({lang}) => {
  return (
    <>
    <footer className="dash-footer">
    <div className="footer-wrapper">
        <div className="py-1">
            <span className="text-muted"> © 2023 AppName</span>
        </div>
    </div>
</footer>
    </>
  )
}

export default Footer