import React from 'react';
import InputBox from '../components/InputBox';
import { Link } from 'react-router-dom';

const Profile = () => {
  return (
    <div>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-auto">
              <div className="page-header-title">
                <h4 className="m-b-10">Perfil</h4>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Painel</Link>
                </li>
                <li className="breadcrumb-item">Perfil</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-xl-10 col-lg-10 mx-auto">
              <div className="tab-content" id="pills-tabContent">
                <div className="card">
                  <div className="tab-pane fade show active" id="apps-setting" role="tabpanel" aria-labelledby="landing-apps-setting">
                    <form method="POST" action="#">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-lg-8 d-flex align-items-center">
                            <h5 className="mb-0">Perfil</h5>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <InputBox name="password" label="Alterar a senha" />
                          </div>
                          <div className="col-md-6">
                            <InputBox name="email" label="E-mail" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
