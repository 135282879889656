import React, { useState, useEffect } from 'react';
import InputBox from '../../components/InputBox';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { apiUrl } from '../../config';
import { useCompany } from '../../components/CompanyProvider ';

const ListenerCreate = () => {
    const { companyId, setCompanyId } = useCompany(); // Use company context

    const [formData, setFormData] = useState({
        document_id: '',
        full_name: '',
    });
    const [errors, setErrors] = useState({});
    const [attributes, setAttributes] = useState([]);
    const [persons, setPersons] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState('');
    const [selectedPerson, setSelectedPerson] = useState('');
    const [attributeValue, setAttributeValue] = useState('');
    const [attributeList, setAttributeList] = useState([]);
    const { id } = useParams(); // Extract the listener ID from the URL
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch attributes from endpoint
        const fetchAttributes = async () => {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        
                const requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
        
                const response = await fetch(`${apiUrl}/BRA/sources`, requestOptions);
                const data = await response.json();
        
                if (response.ok) {
                    // Ensure `data.value` is an array
                    if (Array.isArray(data.value)) {
                        setAttributes(data.value);
                    } else {
                        throw new Error('Unexpected data format: `value` is not an array');
                    }
                } else {
                    throw new Error('Failed to fetch attributes');
                }
            } catch (error) {
                console.error('Error fetching attributes:', error);
            }
        };
        fetchAttributes();
    }, [companyId]);

    useEffect(() => {
        // Fetch persons from endpoint
        const fetchPersons = async () => {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        
                const requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                const response = await fetch(`${apiUrl}/${companyId}/listeners`, requestOptions);
                const data = await response.json();
        
                if (response.ok) {
                    // Ensure `data.value` is an array
                    if (Array.isArray(data.value)) {
                        setPersons(data.value);
                    } else {
                        throw new Error('Unexpected data format: `value` is not an array');
                    }
                } else {
                    throw new Error('Failed to fetch persons');
                }
            } catch (error) {
                console.error('Error fetching persons:', error);
            }
        };
        fetchPersons();
    }, [companyId]);
    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        // Prepare data for submission
        const dataToSave = {
            participation_date: new Date().toISOString(),
            source_id: selectedAttribute,
            person_id: selectedPerson,
            question_answer: attributeValue
        };

    
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
            myHeaders.append("Content-Type", "application/json");
    
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(dataToSave),
                redirect: 'follow'
            };
    
            const response =  fetch(`${apiUrl}/${companyId}/promo/${id}/participation`, requestOptions);

            if (response.ok) {
                const result = await response.json();
                console.log('Response from server:', result);
                navigate(`/report/${id}`);
            } else {
                throw new Error('Failed to save data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    
   

    const handleAttributeChange = (e) => {
        setSelectedAttribute(e.target.value);
    };

    const handlePersonChange = (e) => {
        setSelectedPerson(e.target.value);
    };

    const handleAttributeValueChange = (e) => {
        setAttributeValue(e.target.value);
    };

    const handleAddAttribute = () => {
        if (selectedAttribute && attributeValue) {
            // Check if the attribute already exists in the list
            const existingAttributeIndex = attributeList.findIndex(attr => attr.key === selectedAttribute);
    
            if (existingAttributeIndex !== -1) {
                // If the attribute exists, replace its value
                const updatedAttributeList = attributeList.map((attr, index) => 
                    index === existingAttributeIndex ? { ...attr, value: attributeValue } : attr
                );
                setAttributeList(updatedAttributeList);
            } else {
                // If the attribute does not exist, add it to the list
                setAttributeList([
                    ...attributeList,
                    { key: selectedAttribute, value: attributeValue }
                ]);
            }
    
            // Reset the selected attribute and value fields
            setSelectedAttribute('');
            setAttributeValue('');
        } else {
            console.error('Selected attribute or value is missing');
        }
    };

    const handleEditAttribute = (index) => {
        const attributeToEdit = attributeList[index];
        setSelectedAttribute(attributeToEdit.key);
        setAttributeValue(attributeToEdit.value);
    };

    const handleDeleteAttribute = (index) => {
        const updatedAttributeList = attributeList.filter((_, attrIndex) => attrIndex !== index);
        setAttributeList(updatedAttributeList);
    };
   
    
    return (
        <div>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="page-header-title">
                                <h4 className="m-b-10">Criar participante</h4>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/'}>Painel</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={'/report'}>Relatório</Link>
                                </li>
                                <li className="breadcrumb-item">Criar participante</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-xl-10 col-lg-10 mx-auto">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="card">
                                    <form >
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-lg-8 d-flex align-items-center">
                                                    <h5 className="mb-0">Informações e interesses</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="att" className="form-label">fonte/source</label>
                                                        <select
                                                            className={`form-select ${errors.att ? 'is-invalid' : ''}`}
                                                            id="att"
                                                            name="att"
                                                            value={selectedAttribute}
                                                            onChange={handleAttributeChange}
                                                        ><option value="">Selecione uma pessoa</option>
                                                            {attributes.map((attribute, index) => (
                                                                <option key={index} value={attribute.value}>{attribute.value}</option>
                                                            ))}
                                                        </select>
                                                        {errors.att && <div className="invalid-feedback">{errors.att}</div>}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="person" className="form-label">pessoa/person</label>
                                                        <select
                                                            className={`form-select ${errors.person ? 'is-invalid' : ''}`}
                                                            id="person"
                                                            name="person"
                                                            value={selectedPerson}
                                                            onChange={handlePersonChange}
                                                        ><option value="">Selecione uma pessoa</option>
                                                            {persons.map((person, index) => (
                                                                <option key={index} value={person.id}>{person.full_name}</option>
                                                            ))}
                                                        </select>
                                                        {errors.person && <div className="invalid-feedback">{errors.person}</div>}
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <InputBox
                                                        name="attValue"
                                                        id="attValue"
                                                        label={"Resposta da questão"}
                                                        value={attributeValue}
                                                        onChange={handleAttributeValueChange}
                                                        className={`form-control ${errors.attValue ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.attValue && <div className="invalid-feedback">{errors.attValue}</div>}
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <ul className="list-group">
                                                        {attributeList.map((attr, index) => (
                                                            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                                <span>{attr.key}: {attr.value}</span>
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light-warning btn-sm mx-2"
                                                                        onClick={() => handleEditAttribute(index)}
                                                                    >
                                                                        <i className="ti ti-pencil"></i>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light-danger btn-sm"
                                                                        onClick={() => handleDeleteAttribute(index)}
                                                                    >
                                                                        <i className="ti ti-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-end card-footer">
                                            <Link to={'/listener'} className="btn btn-secondary mx-3">Cancelar</Link>
                                            <button type="submit" className="btn btn-primary" onClick={handleFormSubmit}>Salvar</button>
                                        </div>
                                    </form>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListenerCreate;
