// Layout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { useLocation } from 'react-router-dom';

const Layout = ({ children, isAuthenticated, setIsAuthenticated }) => {

  var _layout = "default"
  const location = useLocation();

  // Logic to determine the layout based on the route
  try {
    _layout = children.props.children.find(x => x.props.path === location.pathname).props.layout;
  } catch (error) {
    _layout = "default";
  }
  return (

    <div>
      {_layout === "default" && <div> 
        <Sidebar/>
        <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
        <main>
          <div className="dash-container">
            <div className="dash-content">
                  {children}
            </div>
          </div>

        </main>
        <Footer />
      </div>
      }
      {_layout === "guest" &&
        <div>
        {children}
        </div>
      }
    </div>
  );
};

export default Layout;
