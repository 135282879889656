import React, { useEffect, useState } from 'react'

const InputBox = ({ label, name,type, value, onChange }) => {

    return (
     <div className="form-group">
        <label for={name} className="form-label">{label }</label>
        <input type={type} value={value} onChange={onChange} name={name} className="form-control "   />
      </div>

    )
}

export default InputBox