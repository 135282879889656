import { BrowserRouter, Route, Routes, useNavigate  } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./screen/Home";
import { useEffect, useState } from "react";
import Listener from "./screen/listener/Listener";
import ListenerCreate from "./screen/listener/ListenerCreate";
import ListenerEdit from "./screen/listener/ListenerEdit";
import Charge from "./screen/charge/Charge";
import ChargeCreate from "./screen/charge/ChargeCreate";
import ChargeEdit from "./screen/charge/ChargeEdit";
import Promotion from "./screen/promotion/Promotion";
import PromotionCreate from "./screen/promotion/PromotionCreate";
import ParticipantCreate from "./screen/promotion/ParticipantCreate";
import PromotionEdit from "./screen/promotion/PromotionEdit";
import Report from "./screen/promotion/Report";
import Login from "./screen/Login";
import Profile from "./screen/Profile";
import ForgetPassword from "./screen/ForgetPassword";
import Redirect from "./components/Redirect";
import Choices from "choices.js";
import ResetPassword from "./screen/ResetPassword";
import { CompanyProvider } from "./components/CompanyProvider ";
import { SnackbarProvider } from "notistack";
const ScrollToTop = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return null;
};
function App() {
  // const navigate = useNavigate();
 
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    

   
    if(localStorage.getItem('token')==null || localStorage.getItem('token')==undefined)
      {
          // localStorage.removeItem("token");
          setIsAuthenticated(false);
      }
      else{
        setToken(localStorage.getItem("token"));
        setIsAuthenticated(true);
      }
    
  });



  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>

    <CompanyProvider>
    <BrowserRouter>
    <ScrollToTop />

      <Layout  isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}>
        <Routes>
          <Route path="/" element={isAuthenticated ? <Home /> : <Redirect />} layout="default" />
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} layout="guest"  />
          <Route path="/forget/password" element={<ForgetPassword setIsAuthenticated={setIsAuthenticated} />} layout="guest"  />
          <Route path="/reset/password" element={<ResetPassword setIsAuthenticated={setIsAuthenticated} />} layout="guest"  />

          <Route path="/profile" element={isAuthenticated ? <Profile /> : <Redirect />} layout="default" setIsAuthenticated={setIsAuthenticated} />



          <Route path="/listener" element={isAuthenticated ? <Listener /> : <Redirect />} layout="default" />
          <Route path="/listener/create" element={isAuthenticated ? <ListenerCreate /> : <Redirect />} layout="default" />
          <Route path="/listener/:id" element={isAuthenticated ? <ListenerEdit /> : <Redirect />}  layout="default" />

          <Route path="/charge"  element={isAuthenticated ? <Charge /> : <Redirect />}  layout="default" />
          <Route path="/charge/create" element={isAuthenticated ? <ChargeCreate /> : <Redirect />} layout="default" />
          <Route path="/charge/edit" element={isAuthenticated ? <ChargeEdit /> : <Redirect />} layout="default" />

          <Route path="/promotion" element={isAuthenticated ? <Promotion /> : <Redirect />} layout="default" />
          <Route path="/promotion/create" element={isAuthenticated ? <PromotionCreate /> : <Redirect />} layout="default" />
          <Route path="/promotion/:id" element={isAuthenticated ? <PromotionEdit /> : <Redirect />} layout="default" />
          <Route path="/report/:id" element={isAuthenticated ? <Report /> : <Redirect />}  layout="default" />
          <Route path="/participant/create/:id" element={isAuthenticated ? <ParticipantCreate /> : <Redirect />}  layout="default" />

        </Routes>
      </Layout>
    </BrowserRouter>
    </CompanyProvider>
    </SnackbarProvider>
  );
}

export default App;
