import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import InputBox from '../../components/InputBox';
import Choices from 'choices.js';
import { apiUrl, formatDate } from "../../config";

import { useCompany } from "../../components/CompanyProvider ";
import { enqueueSnackbar } from "notistack";
import Participants from "./Participants";

function Report() {
  const { companyId } = useCompany(); // Use company context

  const { id } = useParams(); // Extract the listener ID from the URL

  const [summary, setSummary] = useState({});
  const [participationsHourly, setParticipationsHourly] = useState([]);
  const [participationsDaily, setParticipationsDaily] = useState([]);
  const [participationsGenre, setParticipationsGenre] = useState([]);
  const [participationsAgeRange, setParticipationsAgeRange] = useState([]);
  const [participationsZipCode, setParticipationsZipCode] = useState([]);

  const [activeGender, setActiveGender] = useState(null);
  const [totalParticipations, setTotalParticipations] = useState(null);
  const [uniqueParticipants, setUniqueParticipants] = useState(null);

  const handleRowClick = (item) => {
    setActiveGender(item.gender);
    setTotalParticipations(item.total_participations);
    setUniqueParticipants(item.unique_participants);
  };


  const [activeAgeRange, setActiveAgeRange] = useState(null);
  const [totalParticipationsAgeRange, setTotalParticipationsAgeRange] = useState(null);
  const [uniqueParticipantsAgeRange, setUniqueParticipantsAgeRange] = useState(null);

  const handleRowAgeRangeClick = (item) => {
    setActiveAgeRange(item.age_range);
    setTotalParticipationsAgeRange(item.total_participants);
    setUniqueParticipantsAgeRange(item.unique_participants);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [lineDataHourly, setLineDataHourly] = useState({
    labels: [],
    datasets: [
      {
        label: "Participações por Hora",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [lineDataDaily, setLineDataDaily] = useState({
    labels: [],
    datasets: [
      {
        label: "Participações por Dia",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });
  const [lineDataZipCode, setLineDataZipCode] = useState({
    labels: [],
    datasets: [
      {
        label: "participações por CEP - top 15",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });
  const [lineData, setLineData] = useState({
    labels: [],
    datasets: [
      {
        label: " participantes por hora",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    fetchSummary();
    fetchParticipationsHourly();
    fetchParticipationsDaily();
    fetchParticipationsGenre();
    fetchParticipationsAgeRange();
    fetchParticipationsZipCode();
  }, [companyId]);
  const fetchSummary = async () => {
    try {

      const response = await fetch(`${apiUrl}/${companyId}/promo/${id}/report/summary`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text(); // Get error details from response
        throw new Error(`Network response was not ok: ${errorText}`);
      }

      const data = await response.json();
      console.log(data); // Use console.log for debugging

      // Example of more detailed data handling
      if (data.value && Array.isArray(data.value)) {
        setSummary(data.value[0] || {});
      } else {
        console.warn('Unexpected data structure:', data);
        setSummary({});
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const fetchParticipationsHourly = async () => {
    try {

      const response = await fetch(`${apiUrl}/${companyId}/promo/${id}/report/participations-hourly`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setParticipationsHourly(data.value || []);
      const labels = data.value.map((item) => `${item.hour}:00`);
      const datasetData = data.value.map((item) => item.total_participations);
      setLineDataHourly({
        labels,
        datasets: [
          {
            label: "Participações por Hora",
            data: datasetData,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching hourly participations:", error);
    }
  };

  const fetchParticipationsDaily = async () => {
    try {

      const response = await fetch(`${apiUrl}/${companyId}/promo/${id}/report/participations-daily`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setParticipationsDaily(data.value || []);
      const labels = data.value.map((item) => new Date(item.calendar_date).toLocaleDateString());
      const datasetData = data.value.map((item) => item.quantidade_participacoes);
      setLineDataDaily({
        labels,
        datasets: [
          {
            label: "Participações por Dia",
            data: datasetData,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching daily participations:", error);
    }
  };

  const fetchParticipationsGenre = async () => {
    try {

      const response = await fetch(`${apiUrl}/${companyId}/promo/${id}/report/participations-genre`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setParticipationsGenre(data.value || []);
    } catch (error) {
      console.error("Error fetching genre participations:", error);
    }
  };

  const fetchParticipationsAgeRange = async () => {
    try {

      const response = await fetch(`${apiUrl}/${companyId}/promo/${id}/report/participations-age-range`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setParticipationsAgeRange(data.value || []);
    } catch (error) {
      console.error("Error fetching age range participations:", error);
    }
  };

  const fetchParticipationsZipCode = async () => {

    try {

      const response = await fetch(`${apiUrl}/${companyId}/promo/${id}/report/participations-zip-code`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setParticipationsZipCode(data.value || []);
      const labels = data.value.map((item) => `${item.zip_code}`);
      const datasetData = data.value.map((item) => item.total_zip_codes);
      const chartData = {
        labels,
        datasets: [
          {
            label: "Top 15 Participações por CEP",
            data: datasetData,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          },
        ],
      };

      console.log('Chart data:', chartData); // Log chart data
      setLineDataZipCode(chartData);
    } catch (error) {
      console.error("Error fetching zip code participations:", error);
    }
  };
  useEffect(() => {
    // If the participationsGenre array has data, select the first item by default
    if (participationsGenre.length > 0) {
      const firstItem = participationsGenre[0];
      setActiveGender(firstItem.gender);
      setTotalParticipations(firstItem.total_participations);
      setUniqueParticipants(firstItem.unique_participants);
    }
  }, [participationsGenre]);
  useEffect(() => {
    // If the participationsGenre array has data, select the first item by default
    if (participationsAgeRange.length > 0) {
      const firstItem = participationsAgeRange[0];
      setActiveAgeRange(firstItem.age_range);
      setTotalParticipationsAgeRange(firstItem.total_participants);
      setUniqueParticipantsAgeRange(firstItem.unique_participants);
    }
  }, [participationsAgeRange]);




 

  return (
    <div>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-auto">
              <div className="page-header-title">
                <h4 className="m-b-10">Relatório de promoção</h4>
              </div>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>Painel </Link> </li> <li className="breadcrumb-item">
                  <Link to={"/promotion"}>Promoção </Link> </li> <li className="breadcrumb-item">Relatório</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="">
          <div className="row">
            <h3>Sumário</h3>
          </div>
          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <h3 className="mt-4 mb-2">{summary.id}</h3>
                  <h6 className="mb-3">{summary.status_text}</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <h3 className="mt-4 mb-2">{summary.total_participations}</h3>
                  <h6 className="mb-3">Participantes</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <h3 className="mt-4 mb-2">{summary.total_hours_duration}</h3>
                  <h6 className="mb-3">Horas de duração</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
{/**/ }
         <Participants companyId={companyId} id={id} fetchSummary={fetchSummary}/>
{/**/ }
              
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5> participantes por hora </h5>
                </div>
                <div className="card-body">
                  <div style={{ width: "100%" }}>
                    <Line data={lineDataHourly} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5>participantes por dia</h5>
                </div>
                <div className="card-body">
                  <div style={{ width: '100%', }}>
                    <Line data={lineDataDaily} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="row">
                <h5>Gênero</h5>
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="card">
                        <table className="table card-body">
                          <tbody>
                            {participationsGenre.map(item => (
                              <tr
                                key={item.gender}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: activeGender === item.gender ? '#f0f0f0' : 'transparent'
                                }}
                                onClick={() => handleRowClick(item)}
                              >
                                <td>{item.gender}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-lg-6 col-6 mt-2">
                      <div className="card" style={{
                        height: "40%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <h3>{totalParticipations}</h3>
                        <p>Participações</p>
                      </div>
                      <div className="card mt-0" style={{
                        height: "40%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <h3>{uniqueParticipants}</h3>
                        <p>Ouvintes diferentes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="row">
                <h5>Faixa etária</h5>
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="card">
                        <table className="table card-body">
                          <tbody>
                            {participationsAgeRange.map(item => (
                              <tr
                                key={item.age_range}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: activeAgeRange === item.age_range ? '#f0f0f0' : 'transparent'
                                }}
                                onClick={() => handleRowAgeRangeClick(item)}
                              >
                                <td>{item.age_range}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-lg-6 col-6 mt-2">
                      <div className="card" style={{
                        height: "40%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <h3>{totalParticipationsAgeRange}</h3>
                        <p>Participações</p>
                      </div>
                      <div className="card mt-0" style={{
                        height: "40%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                        <h3>{uniqueParticipantsAgeRange}</h3>
                        <p>Ouvintes diferentes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5> participações por CEP - top 15 </h5>
                </div>
                <div className="card-body">
                  <div style={{ width: "100%", }}>
                    <Line data={lineDataZipCode} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Report;