import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { apiUrl, baseUrl, formatDate } from "../config";
import { useCompany } from "../components/CompanyProvider ";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const Home = () => {
  const { companyId, setCompanyId } = useCompany(); // Use company context

  const [latestParticipants, setLatestParticipants] = useState([]);
  const [lineData, setLineData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  });
  const [promoStats, setPromoStats] = useState({
    total_promotions: 0,
    total_participants: 0,
    total_unique_participants: 0,
    active_promotions: [],
    last_participations: [],
    hourly_participations: {},
    top_participants: [],
  });

  const [weekPeriod, setWeekPeriod] = useState('');

  

  const fetchPromoStats = async () => {
    try {
      

      const currentDate = new Date();
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - (currentDate.getDay() || 7) + 1); // Get the Monday of the current week
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Get the Sunday of the current week

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const formatDisplayDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };

      const startDateString = formatDate(startOfWeek);
      const endDateString = formatDate(endOfWeek);

      setWeekPeriod(`Período de ${formatDisplayDate(startOfWeek)} a ${formatDisplayDate(endOfWeek)}`);

      const response = await fetch(`${apiUrl}/${companyId}/promos/dashboard?start_date=${startDateString}&end_date=${endDateString}&limit=10`, {
        method: "GET",
        headers: new Headers({
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }),
        redirect: "follow"
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setPromoStats(data);

      // Process data for chart
      const labels = Object.keys(data.hourly_participations || {});
      const datasetData = Object.values(data.hourly_participations || {});
      setLineData({
        labels,
        datasets: [
          {
            label: "",
            data: datasetData,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
      // Handle error state or alert user
    }
  };

  useEffect(() => {
    if(companyId!=null)
    {
      fetchPromoStats(); // Fetch stats when component mounts

    }
  }, [companyId]);
  return (
    <div>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-auto">
              <div className="page-header-title">
                <h4 className="m-b-10">Painel</h4>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div className="card mt-3">
              <div className="card-header">
              <h5 className="m-b-10">{weekPeriod}</h5>

              </div>
            </div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="theme-avtar bg-primary">
                    <i className="ti ti-files"></i>
                  </div>
                  <p className="text-muted text-sm mt-4 mb-2"> Total </p>
                  <h6 className="mb-3 text-primary"> Promoções </h6>
                  <h3 className="mb-0 text-primary"> {promoStats.total_promotions} </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="theme-avtar bg-info">
                    <i className="ti ti-users"></i>
                  </div>
                  <p className="text-muted text-sm mt-4 mb-2"> Total </p>
                  <h6 className="mb-3 text-info"> Participações </h6>
                  <h3 className="mb-0 text-info"> {promoStats.total_participants} </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="theme-avtar bg-danger">
                    <i className="ti ti-users"></i>
                  </div>
                  <p className="text-muted text-sm mt-4 mb-2"> Total </p>
                  <h6 className="mb-3 text-danger"> Ouvintes Diferentes </h6>
                  <h3 className="mb-0 text-danger"> {promoStats.total_unique_participants} </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5> Participações Por Hora</h5>
              </div>
              <div className="card-body">
                <div style={{ height: "200px" }}>
                  <Line data={lineData} />
                </div>
              </div>
            </div>
          </div>
            <div className="col-lg-6 col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5> Ativa Promoções </h5>
                  <Link to="/promotion" className="btn btn-default buttons-reload btn-light-primary">
                    <span>
                      <i className="ti ti-refresh"></i> Ver tudo
                    </span>
                  </Link>
                </div>
                <div className="card-body">
                  <table className="table card-body">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Contar</th>
                      </tr>
                    </thead>
                    <tbody>
                    {promoStats.active_promotions && promoStats.active_promotions.length > 0 ? (
                      promoStats.active_promotions.map((promotions, index) => (
                        <tr key={index}>
                        <td>{promotions.Name}</td>
                        <td>{promotions.Count}</td>
                      </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">Você não tem promoções ativas</td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
           
            
            <div className="col-lg-6 col-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5> Ouvintes Mais Engajados </h5>
              </div>
              <div className="card-body">
              <table className="table card-body">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Contar</th>
                </tr>
              </thead>
              <tbody>
              {promoStats.top_participants && promoStats.top_participants.length > 0 ? (
                promoStats.top_participants.map((participant, index) => (
                  <tr key={index}>
                  <td>{participant.Name}</td>
                  <td>{participant.Count}</td>
                </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">Você não tem promoções ativas</td>
                </tr>
              )}
               
              </tbody>
            </table>
               
              </div>
            </div>
          </div>

            <div className="col-lg-6 col-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5> Últimas Participações </h5>
              </div>
              <div className="card-body">
              <table className="table card-body">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Nome</th>
                  <th>Promoção</th>
                </tr>
              </thead>
              <tbody>
              {promoStats.last_participations && promoStats.last_participations.length > 0 ? (
                promoStats.last_participations.map((participation, index) => (
                  <tr key={index}>
                  <td>{formatDate(participation.Date)}</td>
                  <td>{participation.Name}</td>
                  <td>{participation.Promo}</td>
                </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">Você não tem promoções ativas</td>
                </tr>
              )}
               
              </tbody>
            </table>
                  
              </div>
            </div>
          </div>
     
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
