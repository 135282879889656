import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { apiUrl } from '../config';
import InputBox from '../components/InputBox';

function Login({ setIsAuthenticated }) {
  const navigate = useNavigate(); // Create a navigate function

  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [submit, setSubmit] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePassChange = (e) => {
    setPass(e.target.value);
  };

  const handleSubmit = async (e) => {
    setSubmit(true);
    e.preventDefault();

    const raw = JSON.stringify({
      username: username,
      pass: pass,
    });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${apiUrl}/membership/login`, requestOptions);
      const result = await response.json();
      //console.log(result); // Debugging log to verify the response

      if (result && result.client_secret != null) {
        const token = result.client_secret;
        localStorage.setItem('username', username);

        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(result.data));
        setResponseMessage('Successfully Login');
        setIsAuthenticated(true);
        setTimeout(() => {
          navigate('/');
        }, 10);
      } else {
        setResponseMessage(result.message);
      }
    } catch (error) {
      //console.log("error", error);
      setResponseMessage("Não foi possível fazer o login, por favor, verifique os dados digitados e tente novamente.");
    } finally {
      setSubmit(false);
    }
  };

  return (
    <section className="h-100 mb-10 gradient-form " style={{ backgroundColor: 'linear-gradient(115.25deg, #ffffff 0%, #f8f9fd 100.25%)', height: '100vh' }}>
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-6 mt-5 pt-5">
            <div className="card rounded-3 text-black ">
              <div className='card-body'>
                <div className="row g-0">
                  <div className="col-md-12">
                    <div className="text-center my-auto">
                      <img
                        src="/assets/images/logo/app-logo.png"
                        alt="logo"
                        className='text-center'
                      />
                    </div>
                    <form>
                      <p className='text-center'>Por favor faça login na sua conta</p>
                      {/* Display the response message */}
                      {responseMessage && (
                        <p className="text-center text-danger">{responseMessage}</p>
                      )}
                      <div className="col-md-12">
                        <InputBox
                          type="text"
                          id="form2Example11"
                          className="form-control"
                          placeholder=""
                          value={username}
                          onChange={handleUsernameChange}
                          required
                          name="username"
                          label="Nome de usuário"
                        />
                      </div>
                      <div className="col-md-12">
                        <InputBox
                          type="password"
                          id="form2Example12"
                          className="form-control"
                          placeholder=""
                          value={pass}
                          onChange={handlePassChange}
                          required
                          name="pass"
                          label="Senha"
                        />
                      </div>
                      <div className="text-center pt-1 mb-5 pb-1">
                        {!submit && (
                          <button
                            className="btn btn-light-primary px-4"
                            type="button"
                            onClick={handleSubmit}
                          >
                          Conecte-se
                          </button>
                        )}
                        {submit && (
                          <button
                            className="btn btn-light-primary px-4"
                            type="button"
                          >
                          Conecte-se
                            <span className="spinner-border spinner-border-sm mx-3" role="status"></span>
                          </button>
                        )}
                        &nbsp;&nbsp;
                        <Link className="text-muted" to={'/forget/password'}>
                        Esqueceu o passe?
                      </Link>
                      </div>
                    </form>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
