import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../config';

const ResetPassword = () => {
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email || ''); // Get email from state or default
  const [newPassword, setNewPassword] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [submit, setSubmit] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    setResponseMessage('');

    try {
      let data = JSON.stringify({
        "username": email,
        "pass": newPassword
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/membership/recovery`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer YOUR_TOKEN_HERE'
        },
        data: data
      };

      const response = await axios.request(config);
      setResponseMessage('Redefinição de senha com sucesso. Agora você pode fazer login com sua nova senha.');
      setIsPasswordReset(true); // Indicate that password reset was successful
    } catch (error) {
      setResponseMessage('Falha ao redefinir a senha. Por favor, tente novamente.');
    } finally {
      setSubmit(false);
    }
  };

  return (
    <section className="h-100 mb-10 gradient-form" style={{ backgroundColor: 'linear-gradient(115.25deg, #ffffff 0%, #f8f9fd 100.25%)', height: '100vh' }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-6 mt-5 pt-5">
            <div className="card rounded-3 text-black">
              <div className="card-body">
                <div className="text-center my-auto">
                  <img
                    src="/assets/images/logo/app-logo.png"
                    alt="logo"
                    className='text-center'
                  />
                </div>
                {isPasswordReset ? (
                  <div className="text-center mt-4">
                    <p className="text-center text-success">{responseMessage}</p>
                    <Link to="/login">
                      <button className="btn btn-light-primary px-4" type="button">
                      Vá para Entrar
                      </button>
                    </Link>
                  </div>
                ) : (
                  <form>
                    <p className="text-center">Digite sua nova senha</p>
                    {responseMessage && (
                      <p className="text-center text-danger">{responseMessage}</p>
                    )}
                    <div className="col-md-12">
                      <input
                        type="password"
                        id="newPassword"
                        className="form-control"
                        placeholder="Digite sua nova senha"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        required
                      />
                    </div>
                    <div className="text-center pt-1 mt-4 mb-5 pb-1">
                      {!submit ? (
                        <button
                          className="btn btn-light-primary px-4"
                          type="button"
                          onClick={handleSubmit}
                        >
                        Redefinir senha
                        </button>
                      ) : (
                        <button
                          className="btn btn-light-primary px-4"
                          type="button"
                        >
                        Redefinindo...
                          <span className="spinner-border spinner-border-sm mx-3" role="status"></span>
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
