import React, { useState, useEffect, useRef } from 'react';
import InputBox from '../../components/InputBox';
import { Link, useNavigate } from 'react-router-dom';
import { apiUrl } from '../../config';
import Choices from 'choices.js';
import { useCompany } from '../../components/CompanyProvider ';
import { enqueueSnackbar } from 'notistack';
const ListenerCreate = () => {
    const { companyId, setCompanyId } = useCompany(); // Use company context

    const [formData, setFormData] = useState({
        document_id: '',
        full_name: '',
    });
    const [errors, setErrors] = useState({});
    const [attributes, setAttributes] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState('');
    const [attributeValue, setAttributeValue] = useState('');
    const [attributeList, setAttributeList] = useState([]);
    const navigate = useNavigate();
    const attributeSelectRef = useRef(null);

    useEffect(() => {
        // Fetch attributes from endpoint
        const fetchAttributes = async () => {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
        
                const requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                const response = await fetch(`${apiUrl}/attributes`, requestOptions);
                const data = await response.json();
                if (response.ok) {
                    setAttributes(data.value || []); // Make sure `data.value` is an array
                } else {
                    throw new Error('Failed to fetch attributes');
                }
            } catch (error) {
                console.error('Error fetching attributes:', error);
            }
        };

        fetchAttributes();
    }, []);
    // useEffect(() => {
    //     const choices = new Choices(attributeSelectRef.current, {
    //         removeItemButton: true,
    //         shouldSort: false,
    //     });

    //     return () => {
    //         choices.destroy(); // Clean up Choices instance on component unmount
    //     };
    // }, [attributes]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAttributeChange = (e) => {
        setSelectedAttribute(e.target.value);
    };

    const handleAttributeValueChange = (e) => {
        setAttributeValue(e.target.value);
    };

    const handleAddAttribute = () => {
        if (selectedAttribute && attributeValue) {
            // Check if the attribute already exists in the list
            const existingAttributeIndex = attributeList.findIndex(attr => attr.key === selectedAttribute);
    
            if (existingAttributeIndex !== -1) {
                // If the attribute exists, replace its value
                const updatedAttributeList = attributeList.map((attr, index) => 
                    index === existingAttributeIndex ? { ...attr, value: attributeValue } : attr
                );
                setAttributeList(updatedAttributeList);
            } else {
                // If the attribute does not exist, add it to the list
                setAttributeList([
                    ...attributeList,
                    { key: selectedAttribute, value: attributeValue }
                ]);
            }
    
            // Reset the selected attribute and value fields
            setSelectedAttribute('');
            setAttributeValue('');
        } else {
            console.error('Selected attribute or value is missing');
        }
    };
    

    const handleEditAttribute = (index) => {
        const attribute = attributeList[index];
        setSelectedAttribute(attribute.key);
        setAttributeValue(attribute.value);
        // setAttributeList(attributeList.filter((_, i) => i !== index));
    };

    const handleDeleteAttribute = (index) => {
        setAttributeList(attributeList.filter((_, i) => i !== index));
    };

    const handleSubmit = async () => {
        setErrors({}); // Clear previous errors
        const { document_id, full_name } = formData;
        const payload = {
            document_id,
            full_name,
            attributes: attributeList
        };

        try {
            const endpoint = `${apiUrl}/${companyId}/listener`;
            const token = localStorage.getItem('token');

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            console.log(data);
            if (data.statusCode === 200) {
                enqueueSnackbar("Record Save Successfuly!");

                // Handle success
                navigate('/listener'); // Redirect to listener list on success
            } else {
                // Handle errors
                setErrors(data.errors || { general: data.msg });
                enqueueSnackbar(data.msg);
            }
        } catch (error) {
            console.error('Error posting data: ', error);
            // Handle network errors or other exceptions
        }
    };

    return (
        <div>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="page-header-title">
                                <h4 className="m-b-10">Criar ouvinte</h4>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/'}>Painel</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={'/listener'}>Ouvinte</Link>
                                </li>
                                <li className="breadcrumb-item">Criar ouvinte</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-xl-10 col-lg-10 mx-auto">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="card">
                                    <form >
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-lg-8 d-flex align-items-center">
                                                    <h5 className="mb-0">Incluir Novo Ouvinte</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {Object.keys(formData).map((key) => (
                                                    <div className="col-md-6" key={key}>
                                                        <div className="form-group">
                                                            <InputBox
                                                                name={key}
                                                                id={key}
                                                                label={key === 'document_id' ? 'CPF' : key === 'full_name' ? 'Nome completo' : key.replace('_', ' ').toUpperCase()}
                                                                value={formData[key]}
                                                                onChange={handleInputChange}
                                                                className={`form-control ${errors[key] ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors[key] && <div className="invalid-feedback">{errors[key]}</div>}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card">
                                    <form>
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-lg-8 d-flex align-items-center">
                                                    <h5 className="mb-0">Informações e interesses</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="att" className="form-label">Categoria da informação ou interesse</label>
                                                        <select
                                                            className={`form-select ${errors.att ? 'is-invalid' : ''}`}
                                                            id="att"
                                                            name="att"
                                                            value={selectedAttribute}
                                                            onChange={handleAttributeChange}
                                                            ref={attributeSelectRef} // Attach the ref to the select element
                                                        >
                                                            <option value="" disabled>Selecione o Atributo</option>
                                                            {attributes.map((attribute) => (
                                                                <option key={attribute.id} value={attribute.id}>
                                                                    {attribute.id}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.att && <div className="invalid-feedback">{errors.att}</div>}
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <InputBox
                                                        name="attValue"
                                                        id="attValue"
                                                        label={"Detalhe da informação ou interesse"}
                                                        value={attributeValue}
                                                        onChange={handleAttributeValueChange}
                                                        className={`form-control ${errors.attValue ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.attValue && <div className="invalid-feedback">{errors.attValue}</div>}
                                                </div>
                                                <div className='col-md-1 mt-4'>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100"
                                                        onClick={handleAddAttribute}
                                                    >
                                                        + 
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <h5>Base de conhecimento sobre o ouvinte</h5>
                                                    <ul className="list-group">
                                                        {attributeList.map((attr, index) => (
                                                            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                                <span>{attr.key}: {attr.value}</span>
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light-warning btn-sm mx-2"
                                                                        onClick={() => handleEditAttribute(index)}
                                                                    >
                                                                        <i className="ti ti-pencil"></i>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light-danger btn-sm"
                                                                        onClick={() => handleDeleteAttribute(index)}
                                                                    >
                                                                        <i className="ti ti-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="text-end">
                                    <Link to={'/listener'} className="btn btn-secondary mx-3">Cancelar</Link>
                                    <button type="button" className="btn btn-primary" onClick={handleSubmit} >Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListenerCreate;
