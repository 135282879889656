import React, { useState, useRef, useEffect } from 'react';
import InputBox from '../../components/InputBox';
import TextAreaBox from '../../components/TextAreaBox';
import { Link, useNavigate } from 'react-router-dom';
import DateInputBox from '../../components/DateInputBox';
import { apiUrl } from '../../config';
import Choices from 'choices.js';
import { useCompany } from '../../components/CompanyProvider ';
import { enqueueSnackbar } from 'notistack';
const PromotionCreate = () => {
    const { companyId, setCompanyId } = useCompany(); // Use company context

    const [inputs, setInputs] = useState({
        id: '',
        summary: '',
        keywords: '',
        begin_date: '',
        ending_date: '',
        greetings_text: '',
        lgpd_text: '',
        banner_url: '',
        question_text: '',
        terms_rules_url: '',
        thanks_text: '',
        results_text: '',
        minimum_age: '',
        attribute_id: '',
        tags: ''
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const attributeSelectRef = useRef(null);
    const [dropdownOptions, setDropdownOptions] = useState([
        { value: '', label: 'Select Category' },
        { value: 'Affinity > Beauty & Fashion > Hair Treatments', label: 'Affinity > Beauty & Fashion > Hair Treatments' },
        { value: 'Affinity > Travel & Tourism > Destinations > Northamerica', label: 'Affinity > Travel & Tourism > Destinations > Northamerica' }
    ]);
    useEffect(() => {
        if (attributeSelectRef.current) {
            // Cleanup previous Choices instance
            if (attributeSelectRef.current.choices) {
                attributeSelectRef.current.choices.destroy();
            }

            // Initialize Choices.js
            const choices = new Choices(attributeSelectRef.current, {
                searchEnabled: true,
                itemSelectText: '',
            });

            // Set choices and select default value
            choices.setChoices(
                dropdownOptions,
                'value',
                'label',
                false
            );

            // Set the default value
            const defaultValue = inputs.attribute_id || dropdownOptions[0]?.value;
            if (defaultValue) {
                choices.setChoiceByValue(defaultValue);
            }

            // Cleanup Choices.js instance on component unmount
            return () => {
                if (choices) {
                    choices.destroy();
                }
            };
        }
    }, [dropdownOptions, inputs.attribute_id]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs(prevInputs => ({
            ...prevInputs,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(inputs.begin_date=="")
            {
                enqueueSnackbar("A data e a hora de início são obrigatórias");
               return;
            }
            if(inputs.ending_date=="")
                {
                    enqueueSnackbar("A data e a hora de término são obrigatórias");
                   return;
                }
        const formatDate = (dateStr) => {
            const date = new Date(dateStr);
            return date.toISOString(); // Converts to YYYY-MM-DDTHH:MM:SS.sssZ format
        };
      
        setErrors({}); // Clear previous errors
        const payload = {
            ...inputs,
            begin_date: formatDate(inputs.begin_date),
            ending_date: formatDate(inputs.ending_date),
            minimum_age: parseInt(inputs.minimum_age, 10) // Ensure minimum_age is an integer
        };

        try {

            const response = await fetch(`${apiUrl}/${companyId}/promo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            console.log(payload)
            
            if (data && data.statusCode == 200) {
                enqueueSnackbar("Promoção criada com sucesso.");
                navigate('/promotion');
            } else {
                setErrors(data.errors || { general: data.msg });
                enqueueSnackbar(data.msg);
            }
        } catch (error) {
            console.error('Error posting data:', error);
            setErrors({ general: 'An error occurred. Please try again later.' });
        }
    };

    return (
        <div>
            {/* Page Header and Navigation */}
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="page-header-title">
                                <h4 className="m-b-10">Incluir Nova Promoção</h4>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/'}>Painel</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={'/promotion'}>Promoções </Link>
                                </li>
                                <li className="breadcrumb-item">Incluir</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Form */}
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-xl-10 col-lg-10 mx-auto">
                            <form onSubmit={handleSubmit}>
                                {/* General Section */}
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="mb-0">Sobre</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <InputBox
                                                    name="id"
                                                    label="Título identificador (ID)"
                                                    value={inputs.id}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.id ? 'is-invalid' : ''}`}
                                                />
                                                {errors.id && <div className="invalid-feedback">{errors.id}</div>}
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox
                                                    name="summary"
                                                    label="Descrição resumida (140 caracteres)"
                                                    value={inputs.summary}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.summary ? 'is-invalid' : ''}`}
                                                />
                                                {errors.summary && <div className="invalid-feedback">{errors.summary}</div>}
                                            </div>
                                            <div className="col-md-6">
                                                <DateInputBox
                                                    name="begin_date"
                                                    label="Data e hora de inicio"
                                                    value={inputs.begin_date}
                                                    onChange={handleChange}
                                                    type="datetime-local"
                                                   
                                                    className={`form-control ${errors.begin_date ? 'is-invalid' : ''}`}
                                                />
                                                {errors.begin_date && <div className="invalid-feedback">{errors.begin_date}</div>}
                                            </div>
                                            <div className="col-md-6">
                                                <DateInputBox
                                                    name="ending_date"
                                                    label="Data e hora de término"
                                                    value={inputs.ending_date}
                                                    onChange={handleChange}
                                                    type="datetime-local"
                                                    className={`form-control ${errors.ending_date ? 'is-invalid' : ''}`}
                                                />
                                                {errors.ending_date && <div className="invalid-feedback">{errors.ending_date}</div>}
                                            </div>
                                            <div className="col-md-6">
                                                <InputBox
                                                    name="keywords"
                                                    label="Palavra chave de ativação do robô (usar #)"
                                                    value={inputs.keywords}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.keywords ? 'is-invalid' : ''}`}
                                                />
                                                {errors.keywords && <div className="invalid-feedback">{errors.keywords}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Robot Section */}
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="mb-0">Robô</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextAreaBox
                                                    name="greetings_text"
                                                    label="1. Texto de apresentação"
                                                    value={inputs.greetings_text}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.greetings_text ? 'is-invalid' : ''}`}
                                                />
                                                {errors.greetings_text && <div className="invalid-feedback">{errors.greetings_text}</div>}
                                            </div>
                                            <div className="col-md-6">
                                            <InputBox
                                                name="banner_url"
                                                label="2. Url do áudio, video ou imagem (opcional)"
                                                value={inputs.banner_url}
                                                onChange={handleChange}
                                                className={`form-control ${errors.banner_url ? 'is-invalid' : ''}`}
                                            />
                                            {errors.banner_url && <div className="invalid-feedback">{errors.banner_url}</div>}
                                        </div>
                                        <div className="col-md-6">
                                        <TextAreaBox
                                            name="results_text"
                                            label="3. Informações sobre o sorteio"
                                            value={inputs.results_text}
                                            onChange={handleChange}
                                            className={`form-control ${errors.results_text ? 'is-invalid' : ''}`}
                                        />
                                        {errors.results_text && <div className="invalid-feedback">{errors.results_text}</div>}
                                    </div>
                                        <div className="col-md-6">
                                        <InputBox
                                            name="terms_rules_url"
                                            label="4. Url dos termos de uso"
                                            value={inputs.terms_rules_url}
                                            onChange={handleChange}
                                            className={`form-control ${errors.terms_rules_url ? 'is-invalid' : ''}`}
                                        />
                                        {errors.terms_rules_url && <div className="invalid-feedback">{errors.terms_rules_url}</div>}
                                    </div>
                                            <div className="col-md-6">
                                                <TextAreaBox
                                                    name="lgpd_text"
                                                    label="5. Resumo da LGPD que o usuário precisa aceitar"
                                                    value={inputs.lgpd_text}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.lgpd_text ? 'is-invalid' : ''}`}
                                                />
                                                {errors.lgpd_text && <div className="invalid-feedback">{errors.lgpd_text}</div>}
                                            </div>

                                            <div className="col-md-6">
                                                <InputBox
                                                    name="minimum_age"
                                                    label="6. Idade mínima"
                                                    type="number"
                                                    value={inputs.minimum_age}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.minimum_age ? 'is-invalid' : ''}`}
                                                />
                                                {errors.minimum_age && <div className="invalid-feedback">{errors.minimum_age}</div>}
                                            </div>
                                          
                                            <div className="col-md-6">
                                                <TextAreaBox
                                                    name="question_text"
                                                    label="7. Pergunta final do concurso cultural (opcional)"
                                                    value={inputs.question_text}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.question_text ? 'is-invalid' : ''}`}
                                                />
                                                {errors.question_text && <div className="invalid-feedback">{errors.question_text}</div>}
                                            </div>
                                          
                                            <div className="col-md-6">
                                                <TextAreaBox
                                                    name="thanks_text"
                                                    label="8. Texto após concluir o cadastro"
                                                    value={inputs.thanks_text}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.thanks_text ? 'is-invalid' : ''}`}
                                                />
                                                {errors.thanks_text && <div className="invalid-feedback">{errors.thanks_text}</div>}
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>

                                {/* Segmentação Section */}
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="mb-0">Segmentação</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <InputBox
                                                    name="tags"
                                                    label="Etiqueta interna"
                                                    value={inputs.tags}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.tags ? 'is-invalid' : ''}`}
                                                />
                                                {errors.tags && <div className="invalid-feedback">{errors.tags}</div>}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="attribute_id" className="form-label">Atributo de mercado</label>
                                                    <select
                                                        className={`form-select ${errors.attribute_id ? 'is-invalid' : ''}`}
                                                        id="attribute_id"
                                                        name="attribute_id"
                                                        ref={attributeSelectRef}
                                                        value={inputs.attribute_id}
                                                        onChange={handleChange}
                                                    >
                                                        {/* Options will be set by Choices.js */}
                                                    </select>
                                                    {errors.attribute_id && <div className="invalid-feedback">{errors.attribute_id}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="text-end">
                                            <Link to={'/promotion'} className="btn btn-secondary mx-3">Cancelar</Link>
                                            <button type="submit" className="btn btn-primary">Salvar</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromotionCreate;
