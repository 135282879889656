

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config';
import Swal from 'sweetalert2';
import { useCompany } from '../../components/CompanyProvider ';

const Listener = () => {
    const { companyId, setCompanyId } = useCompany(); // Use company context

    const [listeners, setListeners] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 5; // Define how many items you want per page

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchListeners(currentPage, searchTerm);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [searchTerm, currentPage,companyId]);

    const fetchListeners = async (page = 1, searchTerm = '') => {
        try {
            const offset = (page - 1) * itemsPerPage;
            const params = {
                "limit": itemsPerPage,
                "offset": offset,
                "where": searchTerm
                    ? { full_name: { like: `%${searchTerm}%` } }
                    : {},
                "sort": { "full_name": "asc" }
            };
            const query = encodeURIComponent(JSON.stringify(params));
            const endpoint = `${apiUrl}/${companyId}/listeners?$query=${query}`;

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch(endpoint, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setListeners(data.value);
            setTotalPages(Math.ceil(data.total / itemsPerPage));
        } catch (error) {
            console.error('Error fetching data: ', error);
            // Handle error state or alert user
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const deleteListener = async (listenerId) => {
        try {
            
            const endpoint = `${apiUrl}/${companyId}/listener/${listenerId}`;

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

            const requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch(endpoint, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Update state to remove deleted listener
            setListeners(listeners.filter(listener => listener.id !== listenerId));
        } catch (error) {
            console.error('Error deleting data: ', error);
            // Handle error state or alert user
        }
    };

    const handleDelete = (listenerId) => {
        Swal.fire({
            title: 'Tem certeza?',
            text: "Você não poderá reverter isso!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, exclua-o!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteListener(listenerId);
                Swal.fire(
                    'Excluído!',
                    'O ouvinte foi excluído.',
                    'sucesso'
                );
            }
        });
    };

    return (
        <div>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="page-header-title">
                                <h4 className="m-b-10">Ouvintes</h4>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/'}>Painel</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={'/listeners'}>Ouvintes</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <Link to="/listener/create" className="btn btn-sm btn-primary float-right ti ti-plus"></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-end'>
                <div className='col-md-3'>
                    <input
                        type="text"
                        placeholder="Pesquisar por..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="form-control mb-3"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <table className="table">
                        <thead>
                            <tr>
                            <th>CPF</th>
                            <th>Nome</th>
                            <th>Atributos</th>
                            <th>Promoções</th>
                            <th>Premiações</th>
                            <th>AÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listeners.map(listener => (
                                <tr key={listener.id}>
                                <td>{listener.document_number}</td>
                                <td>{listener.full_name}</td>
                                <td>{listener.total_attributes}</td>
                                <td>{listener.total_promotions}</td>
                                <td>{listener.total_won}</td>
                                <td>
                                    <Link to={`/listener/${listener.id}`} className="btn btn-sm btn-light-info mr-2">
                                        <i className="ti ti-pencil"></i>
                                    </Link>
                                    <button
                                        className="ms-3 btn btn-sm"
                                        style={{ 
                                            background: '#ffd8e2', 
                                            color: '#BF3636', 
                                            borderColor: '#ffd8e2' 
                                        }}
                                        onClick={() => handleDelete(listener.id)}
                                    >
                                        <i className="ti ti-trash"></i>
                                    </button>
                                </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-auto">
                            <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                            >
                            Anterior
                            </button>
                        </div>
                        <div className="col-auto">
                            <span className="mx-3">
                            Página <strong>{currentPage}</strong> de <strong>{totalPages}</strong>
                            </span>
                        </div>
                        <div className="col-auto">
                            <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                            Próximo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Listener;
