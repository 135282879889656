import React from 'react';

const DateInputBox = ({ label, name, value, onChange,type="date" }) => {
    return (
        <div className="form-group">
            <label htmlFor={name} className="form-label">{label}</label>
            <input 
                type={type} 
                name={name} 
                value={value} 
                onChange={onChange} 
                className="form-control" 
                lang="pt-BR" // Set the language to Brazilian Portuguese
                />
        </div>
    );
}

export default DateInputBox;
