import React, { useEffect, useRef, useState } from 'react';
import { apiUrl } from '../../config';
import { enqueueSnackbar } from 'notistack';
import Choices from 'choices.js';
import { Button, Modal } from 'react-bootstrap';

const Participants = ({ companyId, id, fetchSummary }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [errors, setErrors] = useState({});
    const [sources, setSources] = useState([]);
    const [persons, setPersons] = useState([]);
    const [selectedSource, setSelectedSource] = useState('');
    const [selectedPerson, setSelectedPerson] = useState('');
    const [sourceValue, setSourceValue] = useState('');
    const [participants, setParticipants] = useState([]);

    const sourceSelectRef = useRef(null);
    const personSelectRef = useRef(null);

    useEffect(() => {
        if(companyId!=null)
        {
            fetchParticipations();
            fetchPersons();
            fetchSources();
        }
   
    }, [companyId]);

    const fetchParticipations = async () => {
        try {
            const response = await fetch(`${apiUrl}/${companyId}/promo/${id}/report/participations`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (!response.ok) throw new Error("Network response was not ok");
            const data = await response.json();
            setParticipants(data.value || []);
        } catch (error) {
            console.error("Error fetching participations:", error);
            enqueueSnackbar("Failed to fetch participants data.", { variant: 'error' });
        }
    };

    const fetchPersons = async () => {
        try {
            const response = await fetch(`${apiUrl}/${companyId}/listeners`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (!response.ok) throw new Error('Failed to fetch persons');
            const data = await response.json();
            setPersons(data.value);
            setSelectedPerson(data.value[0]?.id || '');
        } catch (error) {
            console.error('Error fetching persons:', error);
            enqueueSnackbar("Failed to fetch persons.", { variant: 'error' });
        }
    };

    const fetchSources = async () => {
        try {
            const response = await fetch(`${apiUrl}/BRA/sources`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (!response.ok) throw new Error('Failed to fetch sources');
            const data = await response.json();
            setSources(data.value);
            setSelectedSource(data.value[0]?.value || '');
        } catch (error) {
            console.error('Error fetching sources:', error);
            enqueueSnackbar("Failed to fetch sources.", { variant: 'error' });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const dataToSave = {
            participation_date: new Date().toISOString(),
            source_id: selectedSource,
            person_id: selectedPerson,
            question_answer: sourceValue
        };

        try {
            const response = await fetch(`${apiUrl}/${companyId}/promo/${id}/participation`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSave)
            });

            if (response.ok) {
                enqueueSnackbar("Record saved successfully!", { variant: 'success' });
                fetchParticipations();
                handleClose();
                setSelectedSource('');
                setSelectedPerson('');
                setSourceValue('');
                fetchSummary();

            } else {
                throw new Error('Failed to save data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            enqueueSnackbar("Failed to save participation.", { variant: 'error' });
        }
    };

    const handleDeleteSource = async (participationId) => {
        try {
            const response = await fetch(`${apiUrl}/${companyId}/promo/${id}/participation/${participationId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.ok) {
                enqueueSnackbar("Record deleted successfully!", { variant: 'success' });
                fetchParticipations();
                fetchSummary();
            } else {
                throw new Error('Failed to delete participation');
            }
        } catch (error) {
            console.error('Error deleting participation:', error);
            enqueueSnackbar("Failed to delete participation.", { variant: 'error' });
        }
    };

    // Initialize Choices.js for both sources and persons
    useEffect(() => {
        const initializeChoices = (ref, items) => {
            if (ref.current) {
                if (ref.current.choicesInstance) {
                    ref.current.choicesInstance.destroy();
                }
                const choicesInstance = new Choices(ref.current, {
                    searchEnabled: true,
                    itemSelectText: '',
                    choices: items.map(item => ({ value: item.value || item.id, label: item.value || item.full_name }))
                });
                ref.current.choicesInstance = choicesInstance;
            }
        };

        initializeChoices(sourceSelectRef, sources);
        initializeChoices(personSelectRef, persons);

        return () => {
            if (sourceSelectRef.current?.choicesInstance) sourceSelectRef.current.choicesInstance.destroy();
            if (personSelectRef.current?.choicesInstance) personSelectRef.current.choicesInstance.destroy();
        };
    }, [sources, persons]);

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-end">
                <Button onClick={() => setOpen(true)}>Incluir na lista</Button>
                <Modal show={open} onHide={() => setOpen(false)} centered>
                    <div className="card mb-0">
                        <div className="card-body px-0 py-0">
                            <form onSubmit={handleFormSubmit}>
                                <div className="card-header">
                                    <h5 className="mb-0">Adicionar participante</h5>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="att" className="form-label">Fonte</label>
                                        <select
                                            ref={sourceSelectRef}
                                            className="form-select"
                                            id="att"
                                            value={selectedSource}
                                            onChange={(e) => setSelectedSource(e.target.value)}
                                        >
                                            {sources.map((source, index) => (
                                                <option key={index} value={source.value}>{source.value}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="person" className="form-label">Pessoa</label>
                                        <select
                                            ref={personSelectRef}
                                            className="form-select"
                                            id="person"
                                            value={selectedPerson}
                                            onChange={(e) => setSelectedPerson(e.target.value)}
                                        >
                                            {persons.map((person, index) => (
                                                <option key={index} value={person.id}>{person.full_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="attValue" className="form-label">Resposta da questão</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="attValue"
                                            value={sourceValue}
                                            onChange={(e) => setSourceValue(e.target.value)}
                                        />
                                    </div>
                                    <div className="text-end mt-3">
                                        <button type="button" className="btn btn-secondary mx-3" onClick={() => setOpen(false)}>Cancelar</button>
                                        <button type="submit" className="btn btn-primary">Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Data da inscrição</th>
                                <th>Resposta da pergunta final</th>
                                <th>Vencedor</th>
                                <th>Nome</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {participants.map(item => (
                                <tr key={item.id}>
                                    <td>{new Date(item.participation_date).toLocaleDateString()}</td>
                                    <td>{item.question_answer}</td>
                                    <td>{item.won ? "Sim" : "Não"}</td>
                                    <td>{item.full_name}</td>
                                    <td>
                                        <button className="btn btn-danger btn-sm" onClick={() => handleDeleteSource(item.person_id)}>Excluir</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Participants;
