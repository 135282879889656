// TextAreaBox.jsx
import React from 'react';

const TextAreaBox = ({ name, label, value, onChange, className }) => {
    return (
        <div className="form-group">
            <label htmlFor={name} className="form-label">{label}</label>
            <textarea
                id={name}
                name={name}
                className={className}
                value={value || ''} // Ensure value defaults to an empty string
                onChange={onChange}
            />
        </div>
    );
};

export default TextAreaBox;
