import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Choices from "choices.js";
import { apiUrl } from "../config";
import { useCompany } from "../components/CompanyProvider ";

const Header = ({ menu, isAuthenticated, setIsAuthenticated }) => {
  const { companyId, setCompanyId } = useCompany(); // Use company context

  const location = useLocation();
  const [inputs, setInputs] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [mobSidebarActive, setMobSidebarActive] = useState(false);
  const selectRef = useRef(null);
  const navigate = useNavigate();

  const getActiveClass = (path, exact = false) => {
    return exact ? location.pathname === path ? 'active' : '' : location.pathname.includes(path) ? 'active' : '';
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      try {
        const response = await fetch(`${apiUrl}/membership/companies`, requestOptions);
        const result = await response.json();
        const optionList = result.value;

        const options = optionList.map(company => ({ value: company.id, label: company.friendly_name }));
        setDropdownOptions(options);
        const savedCompany = companyId || options[0]?.value;
        setInputs({ company: savedCompany });
        setCompanyId(savedCompany);
      } catch (error) {
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
          logout();
        } else {
          console.error("Error fetching companies:", error);
        }
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectRef.current) {
      // Cleanup previous Choices instance
      if (selectRef.current.choices) {
        selectRef.current.choices.destroy();
      }
  
      // Initialize Choices.js
      const choices = new Choices(selectRef.current, {
        searchEnabled: true,
        itemSelectText: '',
      });
  
      // Set choices after initializing Choices.js
      choices.setChoices(
        dropdownOptions,
        'value',
        'label',
        false
      );
  
      // Get saved company ID from local storage or default to first option
      const defaultValue = companyId || dropdownOptions[0]?.value;
      setCompanyId(defaultValue);
  
      if (defaultValue) {
        choices.setChoiceByValue(defaultValue);
      }

      setInputs((prevInputs) => ({
        ...prevInputs,
        category: defaultValue
      }));
  
      // Cleanup Choices.js instance on component unmount
      return () => {
        if (choices) {
          choices.destroy();
        }
      };
    }
  }, [dropdownOptions,companyId]);
  
  useEffect(() => {
    setMobSidebarActive(false);
  }, [location]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
    setCompanyId(value);

  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("username");
    localStorage.removeItem("company_id");

    setCompanyId(null);
    setIsAuthenticated(false);
    setTimeout(() => {
      navigate("/login");
    }, 10);
  };

  return (
    <div>
      <nav className={`dash-sidebar light-sidebar transprent-bg active dash-trigger ${mobSidebarActive ? 'mob-sidebar-active' : ""}`}>
        <div className="navbar-wrapper">
          <div className="m-headers logo-col">
            <Link to="/" className="b-brand">
              <img
                src="/assets/images/logo/app-logo.png"
                className="footer-dark-logo mx-auto"
                style={{ width: '130px', marginLeft: '0px' }}
                alt=''
              />
            </Link>
          </div>
          <div className="navbar-content">
            <ul className="dash-navbar">
              <li className={`dash-item dash-hasmenu ${getActiveClass('/', true)}`}>
                <Link to="/" className="dash-link">
                  <span className="dash-micon"><i className="ti ti-home"></i></span>
                  <span className="dash-mtext">Painel</span>
                </Link>
              </li>
              <li className={`dash-item dash-hasmenu ${getActiveClass('/promotion')}`}>
                <Link to="/promotion" className="dash-link">
                  <span className="dash-micon"><i className="ti ti-speakerphone"></i></span>
                  <span className="dash-mtext">Promoções</span>
                </Link>
              </li>
              <li className={`dash-item dash-hasmenu ${getActiveClass('/charge')}`}>
                <Link to="/charge" className="dash-link">
                  <span className="dash-micon"><i className="ti ti-currency-dollar"></i></span>
                  <span className="dash-mtext">Cobrança</span>
                </Link>
              </li>
              <li className={`dash-item dash-hasmenu ${getActiveClass('/listener')}`}>
                <Link to="/listener" className="dash-link">
                  <span className="dash-micon"><i className="ti ti-users"></i></span>
                  <span className="dash-mtext">Ouvintes</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <header className="dash-header transprent-bg">
        <div className="header-wrapper">
          <div className="me-auto col-md-4 col-8">
            <div className="mt-3">
              <select
                name="company"
                ref={selectRef}
                value={inputs.company || ""}
                onChange={handleDropdownChange}
                style={{ display: "none" }} // Hide the native select element
              >
                {/* Options are dynamically set by Choices.js */}
              </select>
            </div>
          </div>
          <div className="me-auto dash-mob-drp">
            <ul>
              <li className="dropdown dash-h-item drp-company"></li>
              <li className="dash-h-item mob-hamburger">
                <a href="#!" onClick={() => { setMobSidebarActive(!mobSidebarActive) }} className="dash-head-link" id="mobile-collapse">
                  <div className="hamburger hamburger--arrowturn">
                    <div className="hamburger-box">
                      <div className="hamburger-inner"></div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="ms-auto">
            <ul className="list-unstyled d-flex align-items-center">
              <li className="dropdown dash-h-item drp-company">
                <a
                  className="dash-head-link dropdown-toggle arrow-none me-0"
                  data-bs-toggle="dropdown"
                  href="/#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <span>
                    <img
                      src="/assets/images/avatar/avatar.png"
                      className="rounded-circle mr-1"
                      alt=""
                    />
                  </span>
                  <span className="hide-mob ms-2" style={{ color: "#037F8C" }}>
                    {localStorage.getItem('username')}
                  </span>
                  <i className="ti ti-chevron-down drp-arrow nocolor hide-mob"></i>
                </a>
                <div className="dropdown-menu dash-h-dropdown dropdown-menu-lg">
                  <Link to="/profile" className="dropdown-item">
                    <i className="ti ti-user"></i>
                    <span>Perfil</span>
                  </Link>
                  <Link
                    to="/login"
                    className="dropdown-item"
                    onClick={logout}
                  >
                    <i className="ti ti-power"></i>
                    <span>Logout</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
