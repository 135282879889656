import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl, formatDate } from '../../config';
import Swal from 'sweetalert2';
import { useCompany } from '../../components/CompanyProvider ';
import { enqueueSnackbar } from 'notistack';

const Promotion = () => {
    const { companyId, setCompanyId } = useCompany(); // Use company context

    const [promotions, setPromotions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedPromotion, setSelectedPromotion] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 5; // Define how many items you want per page
    
   

   

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
    };
    
    useEffect(() => {
        if(companyId!=null)
        {
        const timeoutId = setTimeout(() => {
            fetchPromotions(currentPage, searchTerm);
        }, 500);
        return () => clearTimeout(timeoutId);
    }

    }, [searchTerm, currentPage,companyId]);
   
    
 // Example of including the search term in the API call:
const fetchPromotions = async (page = 1, searchTerm = '') => {
    try {
        const offset = (page - 1) * itemsPerPage;
        const query = {
            offset: offset,
            limit: itemsPerPage,
            where: searchTerm
                ? {
                      id: { like: `%${searchTerm}%` },
                  }
                : { id: { notEqual: '' } },
            sort: { begin_date: 'desc' },
        };

        const endpoint = `${apiUrl}/${companyId}/promos?$query=${encodeURIComponent(
            JSON.stringify(query)
        )}`;

        const myHeaders = new Headers();
        myHeaders.append(
            'Authorization',
            `Bearer ${localStorage.getItem('token')}`
        );

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        const response = await fetch(endpoint, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if(data!=null && data?.value)
        {
        setPromotions(data.value);
        setTotalPages(Math.ceil(data.total / itemsPerPage));

        }
        else
        {
        setPromotions([]);
        setTotalPages(0);
        }
    } catch (error) {
        console.error('Error fetching data: ', error);
    }
};

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            fetchPromotions(currentPage + 1);
        }
    };
    
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            fetchPromotions(currentPage - 1);
        }
    };
    
    const deletePromotion = async (promotionId) => {
        try {
            const endpoint = `${apiUrl}/${companyId}/promo/${promotionId}`;

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

            const requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch(endpoint, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Update state to remove deleted promotion
            setPromotions(promotions.filter(promotion => promotion.id !== promotionId));
        } catch (error) {
            console.error('Error deleting data: ', error);
            // Handle error state or alert user
        }
    };

    const handleDelete = (promotionId) => {
        Swal.fire({
            title: 'Tem certeza?',
            text: "Você não poderá reverter isso",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, excluir'
        }).then((result) => {
            if (result.isConfirmed) {
                deletePromotion(promotionId);
                Swal.fire(
                    'Excluído',
                    '',
                    'success'
                );
            }
        });
    };

    const handleStatusChange = async (promotionId, newStatus) => {
        try {
            const endpoint = `${apiUrl}/${companyId}/promo/${promotionId}/is-active/${newStatus}`;

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

            const requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch(endpoint, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchPromotions(currentPage, searchTerm);
            // Update promotion status locally
            // setPromotions(promotions.map(promotion => 
            //     promotion.id === promotionId ? { ...promotion, is_active: newStatus } : promotion
            // ));
        } catch (error) {
            console.error('Error updating status: ', error);
            // Handle error state or alert user
        }
    };


   
    const filteredPromotions = promotions.filter((promo) =>
        Object.values(promo).some((value) =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );


    const drawPromotions = async (promotionId) => {
        try {
            const endpoint = `${apiUrl}/${companyId}/promo/${promotionId}/prize-draw`;

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

            const requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch(endpoint, requestOptions);
            const data = await response.json();

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            else {
                enqueueSnackbar(data.msg);
            }
            fetchPromotions(currentPage, searchTerm);
           
        } catch (error) {
            console.error('Error updating status: ', error);
            // Handle error state or alert user
        }
    };
  
    
    return (
        <div>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <div className="page-header-title">
                                <h4 className="m-b-10">Promoções</h4>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/'}>Painel </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={'/promotion'}>Promoções </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <Link to="/promotion/create" className="btn btn-sm btn-primary float-right ti ti-plus"></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-end'>
                <div className='col-md-3'>
                    <input
                        type="text"
                        placeholder="Pesquisar por..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="form-control mb-3"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Promoção</th>
                                <th>Dt.Início</th>
                                <th>Dt.Final</th>
                                <th>Participantes</th>
                                <th>Situação</th>

                                <th>AÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
    {filteredPromotions.map(promotion => (
        <tr key={promotion.id}>
            <td>{promotion.id}</td>
            <td>{formatDate(promotion.begin_date)}</td>
            <td>{formatDate(promotion.ending_date)}</td>
            <td>{promotion.total_participants}</td>
            <td>{promotion.status_text}</td>

            
            <td>
            
                        {(promotion.status == '2') && (
                    <button className="btn btn-sm btn-light-success mx-2" onClick={() => handleStatusChange(promotion.id, true)}>
                        Ativar
                    </button>
                )}
                {(promotion.status == '1' || promotion.status == '3') && (
                    <button className="btn btn-sm btn-light-danger mx-2" onClick={() => handleStatusChange(promotion.id, false)}>
                    Desativar
                    </button>
                )}
                <Link to={`/report/${promotion.id}`} className="btn btn-sm btn-light-dark">
                            <i className="ti ti-report"></i>
                        </Link>
                {promotion.status_text === 'Aguardando sorteio' && (
                    <button className="btn btn-sm btn-light-warning mx-2" onClick={() => drawPromotions(promotion.id)}>
                        <i className="ti ti-gift"></i>
                    </button>
                )}
                {promotion.status_text !== 'Encerrada' && (
                        <Link to={`/promotion/${promotion.id}`} className="btn btn-sm btn-light-info mx-2">
                            <i className="ti ti-pencil"></i>
                        </Link>
                       
                      
                )}
                   {promotion.status_text !== 'Encerrada' && (
                       
                        <button className="btn btn-sm btn-light-danger mx-2" onClick={() => handleDelete(promotion.id)}>
                            <i className="ti ti-trash"></i>
                        </button>
                )}

               
            </td>
        </tr>
    ))}
</tbody>

                    </table>
                    <div className="row justify-content-center align-items-center">
                    <div className="col-auto">
                        <button 
                            className="btn btn-sm btn-outline-primary" 
                            onClick={handlePreviousPage} 
                            disabled={currentPage === 1}
                        >
                        Anterior
                        </button>
                    </div>
                    <div className="col-auto">
                        <span className="mx-3">
                        Página <strong>{currentPage}</strong> de<strong>{totalPages}</strong>
                        </span>
                    </div>
                    <div className="col-auto">
                        <button 
                            className="btn btn-sm btn-outline-primary" 
                            onClick={handleNextPage} 
                            disabled={currentPage === totalPages}
                        >
                        Próximo
                        </button>
                    </div>
                </div>
                

                </div>
            </div>

           
        </div>
    );
};

export default Promotion;
